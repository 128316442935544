import React, { useEffect, useRef } from "react";
import Reveal from "reveal.js";
import "reveal.js/dist/reveal.css";
// import "reveal.js/dist/theme/white.css";

import dg7 from "assets/imgs/digital_wallets/dg7.png";

import ezy_app1 from "assets/imgs/digital_wallets/ezy_app1.jpg";
import ezy_app2 from "assets/imgs/digital_wallets/ezy_app2.jpg";
import ezy_app3 from "assets/imgs/digital_wallets/ezy_app3.jpg";

import dg_merchant1 from "assets/imgs/digital_wallets/ezy_merchant1.jpg";
import dg_merchant2 from "assets/imgs/digital_wallets/ezy_merchant2.jpg";
import dg_merchant3 from "assets/imgs/digital_wallets/ezy_merchant3.jpg";
import dg_merchant4 from "assets/imgs/digital_wallets/ezy_merchant4.jpg";

import pt1 from "assets/imgs/proptech/pt1.jpg";
import pt2 from "assets/imgs/proptech/pt2.jpg";
import pt3 from "assets/imgs/proptech/pt3.jpg";

import ams1 from "assets/imgs/asset_management_solution/ams1.jpg";
import ams2 from "assets/imgs/asset_management_solution/ams2.jpg";
import ams3 from "assets/imgs/asset_management_solution/ams3.jpg";
import singleton from "assets/imgs/asset_management_solution/singleton.png";

import bot1 from "assets/imgs/crm_bot/bot1.jpg";

import infra from "assets/imgs/3E/infra.png";
import echelon from "assets/imgs/3E/echelon.png";
import hero from "assets/imgs/3E/3e_hero.png";
import hiw from "assets/imgs/3E/hiw.png";

import mp1 from "assets/imgs/market_places/mp1.jpg";
import mp2 from "assets/imgs/market_places/mp2.jpg";
import mp3 from "assets/imgs/market_places/mp3.jpg";
import mp4 from "assets/imgs/market_places/mp4.jpg";

import ota1 from "assets/imgs/ota/ota1.jpg";
import ota2 from "assets/imgs/ota/ota2.jpg";
import ota4 from "assets/imgs/ota/ota4.jpg";
import ota5 from "assets/imgs/ota/ota5.jpg";
import ota6 from "assets/imgs/ota/ota6.jpg";
import ota7 from "assets/imgs/ota/ota7.jpg";

import ride1 from "assets/imgs/ride/ride1.png";
import ride2 from "assets/imgs/ride/ride2.png";
import ride3 from "assets/imgs/ride/ride3.png";
import ride4 from "assets/imgs/ride/ride4.png";

import kool1 from "assets/imgs/kool/kool1.png";
import kool2 from "assets/imgs/kool/kool2.png";
import kool3 from "assets/imgs/kool/kool3.png";
import kool4 from "assets/imgs/kool/kool4.png";

import charity1 from "assets/imgs/charity/charity1.png";
import charity2 from "assets/imgs/charity/charity2.png";
import charity3 from "assets/imgs/charity/charity3.png";
import charity4 from "assets/imgs/charity/charity4.png";

import stealth from "assets/imgs/stealth.png";
import puzzle from "assets/imgs/puzzle.png";

import "./Slide.css";
import { Card } from "components/Card/Card.js";

export default function Slide() {
  const deckRef = useRef(null);

  useEffect(() => {
    // Prevents double initialization in strict mode
    if (deckRef.current) return;

    deckRef.current = new Reveal({
      transition: "convex",
      embedded: false,
      controlsTutorial: true,
      // other config options
      // autoAnimate: true,
    });

    deckRef.current.initialize();

    return () => {
      try {
        if (deckRef) {
          deckRef.destroy();
          deckRef = null;
        }
      } catch (e) {
        console.warn("Reveal.js destroy call failed.");
      }
    };
  }, []);

  return (
    // Your presentation is sized based on the width and height of
    // our parent element. Make sure the parent is not 0-height.
    <main>
      <div className="reveal">
        <div className="slides">
          <section>
            <h2 r-fit-text>INTERESTING QUOTES</h2>
            <p className="fragment fade-up">
              “Gold is money, the rest is credit.”
              <span className="author">— J.P. Morgan</span>
            </p>

            <p className="fragment fade-up">
              “In the absence of the gold standard, there is no way to protect
              savings from confiscation through inflation.”
              <span className="author">— Alan Greenspan</span>
            </p>

            <p className="fragment fade-up">
              “Gold still represents the ultimate form of payment in the world.
              Fiat money in extremis is accepted by nobody. Gold is always
              accepted.”
              <span className="author">— Alan Greenspan</span>
            </p>
            <p className="fragment fade-up">
              “Edison's electric light did not come about from the continuous
              improvement of candles.”
              <span className="author">— Oren Harari</span>
            </p>
          </section>

          <section>
            <h2 r-fit-text>THE CONTEXT</h2>
            <p className="fragment fade-up">
              First and foremost, Gold Remains Deeply Undervalued as on NASDAQ
              website{" "}
              <a
                href="https://www.nasdaq.com/articles/gold-remains-deeply-undervalued"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </p>

            <p className="fragment fade-up">
              Indeed, a <strong>Gold revaluation</strong> could transform one's
              financial status overnight. However, without{" "}
              <strong>anticipation</strong>, not only the precious metals supply
              would be gone but mining stock share prices would go through the
              roof <strong>✔️</strong>
            </p>

            <p className="fragment fade-up">
              Moreover, <strong>BlackRock CEO Larry Fink</strong> said{" "}
              <a
                href="https://www.cnbc.com/2024/01/12/blackrocks-larry-fink-says-bitcoin-etfs-are-just-the-first-step-in-the-technological-revolution-of-finance.html"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>{" "}
              : Bitcoin ETFs are "step one in the technological revolution in
              the financial markets".
            </p>
            <p className="fragment fade-up">
              <strong>Step two is Tokenization</strong>. Indeed,{" "}
              <strong>Tokenization</strong> of real world assets such as Gold
              has gained popularity among financial institutions who are
              cautious on crypto assets but keen on the underlying blockchain
              technology.
            </p>
          </section>

          <section>
            <h2>THE PROBLEM WE ARE SOLVING ?</h2>

            <p>
              Within the previous context and Web3 [Read | Write | Own],
              tackling inflation through a digital monetary system built around{" "}
              <strong>EZG</strong>, a digital currency as good as gold ✅
            </p>
          </section>

          <section>
            <h2>HOW ARE WE SOLVING IT ?</h2>
          </section>

          <section>
            <h2>2 tenets</h2>

            <p className="fragment fade-up">
              <strong>EZG</strong>, a powerful and borderless medium of
              exchange, a tokenized & blockchained form of physical gold, that
              can be sent over the Internet.
            </p>
            <p className="fragment fade-up">
              <strong>3E</strong>, a native ecosystem of financial services
              built around <strong>EZG</strong>
            </p>
          </section>

          <section>
            <h2 r-fit-text>
              Functionally, <strong>3E</strong> comprises the following:{" "}
            </h2>

            <p className="fragment fade-up">
              <strong>EZY</strong>, a full-reserve digital and mobile banking
              platform built around <strong>EZG</strong> (P2P transfers, funds
              deposits/withdrawals)
            </p>
            <p className="fragment fade-up">
              <strong>ECHELON</strong>, a large digital ecosystem of essential
              services where <strong>EZG</strong> is natively accepted (online
              goods & services payments) with a set of mechanisms to dispute or
              reverse charges.
            </p>
            <p className="fragment fade-up">
              <strong>EPSILON</strong>, a revolutionary wealth management
              platform centered around the resolution of a challenge, mentioned
              long ago by the great Warren Buffet:{" "}
              <strong>"Making gold a productive asset"</strong>
            </p>
          </section>

          <section>
            <h3>
              All in all, to many netizens, the combination of{" "}
              <strong>EZG</strong> and <strong>3E</strong> is the real deal, one
              of the greatest thing since sliced bread:{" "}
              <strong>
                SAVE Gold, SPEND Gold, EARN Gold, anytime, anywhere and
                digitally
              </strong>
              🌻
            </h3>
          </section>

          <section>
            <h3>THAT WAS FOR THE WORDING.</h3>
            <h3>LET US SHOW YOU OUR DOING</h3>
          </section>

          <section>
            <section>
              <h5>Digital Wallet within Web3 context</h5>
              <p className="subtitle">
                <strong>(Read | Write | Own)</strong>
              </p>
              <div className="card-group">
                <Card className="card" source={ezy_app1} />
                <Card className="card" source={ezy_app2} />
                <Card className="card" source={ezy_app3} />
                <Card className="card" source={dg7} />
              </div>
            </section>

            <section>
              <div className="card-group">
                <Card className="card" source={dg_merchant1} />
                <Card className="card" source={dg_merchant2} />
                <Card className="card" source={dg_merchant3} />
                <Card className="card" source={dg_merchant4} />
              </div>
            </section>
          </section>

          <section>
            <section>
              <h2>3E ECOSYSTEM</h2>
              <div className="card-group">
                <img className="illustration" src={hero} />
              </div>
            </section>
            <section>
              <img className="illustration" src={hiw} />
            </section>
            <section>
              <img className="illustration" src={echelon} />
            </section>
            <section>
              <img className="illustration" src={infra} />
            </section>
          </section>

          <section data-transition="slide">
            <h5>EZY PROPERTY</h5>
            <div className="card-group">
              <Card source={pt1} />
              <Card source={pt2} />
              <Card source={pt3} />
            </div>
          </section>
          <section>
            <section>
              <h5>EPSILON</h5>
              <div className="card-group">
                <Card source={ams1} />
                <Card source={ams2} />
                <Card source={ams3} />
              </div>
            </section>

            <section>
              <p className="intro_text">
                Every <strong>Epsilon</strong> participant has a physical access
                to its stacked Gold bars and a digital access to{" "}
                <strong className="secondary_strong">Singleton</strong>.
              </p>
              <p className="intro_text">
                The latter provides the online platform similar to the stock
                market for transparency and accountability while offering a real
                time portfolio performance tracking and direct yields captures.
                Intermediaries are not necessary.
              </p>
              <p className="intro_text">
                Operations requires neither research from analysts, nor advisors
                or brokers: the digital embodiment of independent asset
                management.✔️
              </p>

              <img className="illustration" src={singleton} />
            </section>
          </section>

          <section data-transition="slide">
            <h5>EZY SHOPPING [MODO]</h5>

            <div className="card-group">
              <Card className="card" source={mp3} />
              <Card className="card" source={mp2} />
              <Card className="card" source={mp4} />
              <Card className="card" source={mp1} />
            </div>
          </section>

          <section data-transition="slide">
            <section>
              <h5>EZY FLY</h5>
              <div className="card-group">
                <Card className="card" source={ota2} />
                <Card className="card" source={ota4} />
                <Card className="card" source={ota5} />
              </div>
            </section>
            <section>
              <div className="card-group">
                <Card className="card" source={ota6} />
                <Card className="card" source={ota7} />
                <Card className="card" source={ota1} />
              </div>
            </section>
          </section>

          <section data-transition="slide">
            <h5>EZY BOT</h5>
            <p className="subtitle">
              <strong>
                [Below the Telegram version. The Signal version in the pipe ...
                ]
              </strong>
            </p>
            <div className="card-group">
              <Card className="card" source={bot1} />
            </div>
          </section>

          <section data-transition="slide">
            <h5>EZY RIDE</h5>

            <div className="card-group">
              <Card className="card" source={ride1} />
              <Card className="card" source={ride2} />
              <Card className="card" source={ride3} />
              <Card className="card" source={ride4} />
            </div>
          </section>

          <section data-transition="slide">
            <h5>EZY FOOD [KOOL]</h5>

            <div className="card-group">
              <Card className="card" source={kool1} />
              <Card className="card" source={kool2} />
              <Card className="card" source={kool3} />
              <Card className="card" source={kool4} />
            </div>
          </section>

          <section data-transition="slide">
            <h5>EZY CHARITY</h5>

            <div className="card-group">
              <Card className="card" source={charity1} />
              <Card className="card" source={charity2} />
              <Card className="card" source={charity3} />
              <Card className="card" source={charity4} />
            </div>
          </section>

          <section data-transition="slide">
            <h5>AND MUCH MORE IN THE MAKING</h5>

            <img
              className="stealth"
              src={stealth}
              alt="image of a stealth plane"
            />
          </section>

          <section className="contact-slide" data-transition="slide">
            <img
              className="puzzle"
              src={puzzle}
              alt="4 pieces of a puzzle fitting together"
            />
            <h6>You see a fit ?</h6>
            <a href="&#77;&#65;&#73;&#108;&#84;&#79;&#58;&#99;&#111;&#110;&#116;&#97;&#99;&#116;&#64;&#51;&#101;&#46;&#110;&#101;&#116;&#46;&#99;&#111;">
              <button className="submit_btn">Contact Us</button>
            </a>
          </section>
        </div>
      </div>
    </main>
  );
}
