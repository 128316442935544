import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="121.864"
      height="95.712"
      viewBox="0 0 121.864 95.712"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.183"
          x2="0.856"
          y1="0.5"
          y2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#6aca7b"></stop>
          <stop offset="1" stopColor="#00b9ac"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="0.546"
          x2="0.955"
          y1="0.5"
          y2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#6aca7b" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#00b9ac"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="0"
          x2="1"
          y1="0.5"
          y2="0.5"
          xlinkHref="#linear-gradient"
        ></linearGradient>
      </defs>
      <g
        data-name="Group 3"
        transform="translate(-385.335 -320.076) translate(-2249 72)"
      >
        <path
          fill="url(#linear-gradient)"
          d="M2634.335 259.118a10.709 10.709 0 0010.71 10.709h32.117a7.194 7.194 0 017.193 7.193v2.018a7.193 7.193 0 01-7.193 7.193h-25.1a10.71 10.71 0 00-10.71 10.71 10.71 10.71 0 0010.71 10.71h25.1a7.193 7.193 0 017.193 7.193 7.193 7.193 0 01-7.193 7.192h-32.117a10.71 10.71 0 00-10.71 10.71 10.71 10.71 0 0010.71 10.71h60.729v-95.048h-60.729a10.71 10.71 0 00-10.71 10.71z"
          data-name="Path 1"
        ></path>
        <path
          fill="url(#linear-gradient-2)"
          d="M2634.335 259.118a10.709 10.709 0 0010.71 10.709h32.117a7.194 7.194 0 017.193 7.193v2.018a7.193 7.193 0 01-7.193 7.193h-25.1a10.71 10.71 0 00-10.71 10.71 10.71 10.71 0 0010.71 10.71h25.1a7.193 7.193 0 017.193 7.193 7.193 7.193 0 01-7.193 7.192h-32.117a10.71 10.71 0 00-10.71 10.71 10.71 10.71 0 0010.71 10.71h60.729v-95.048h-60.729a10.71 10.71 0 00-10.71 10.71z"
          data-name="Path 2"
          style={{ mixBlendMode: "multiply", isolation: "isolate" }}
        ></path>
        <g data-name="Group 1">
          <g data-name="Group 1">
            <path
              fill="url(#linear-gradient-3)"
              d="M2745.489 343.456h-46.448a14.282 14.282 0 01-14.282-14.282v-66.485a14.282 14.282 0 0114.282-14.281h46.448a10.71 10.71 0 0110.71 10.71 10.709 10.709 0 01-10.71 10.709h-39.31v52.209h39.31a10.71 10.71 0 0110.71 10.71 10.71 10.71 0 01-10.71 10.71z"
              data-name="Path 3"
            ></path>
          </g>
          <g data-name="Group 2">
            <rect
              width="35.691"
              height="21.42"
              fill="url(#linear-gradient-3)"
              data-name="Rectangle 1"
              rx="10.71"
              transform="translate(2713.486 286.231)"
            ></rect>
          </g>
        </g>
        <g
          data-name="Group 2"
          opacity="0.6"
          style={{ mixBlendMode: "screen", isolation: "isolate" }}
        >
          <path
            fill="none"
            stroke="#707070"
            strokeMiterlimit="10"
            strokeWidth="0.665"
            d="M2699.041 343.456a14.282 14.282 0 01-14.282-14.282v-66.485a14.282 14.282 0 0114.282-14.281"
            data-name="Path 4"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
