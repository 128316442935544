import "./ImportForm.css";
import React from "react";
import { useState } from "react";
import pb from "lib/pocketbase.js";

export default function ImportForm() {
  const [emailsList, setEmailsList] = useState(null);
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [uploadCompleted, setUploadCompleted] = useState(false);

  // useEffect(() => {}, [emailsList]);

  const extractFile = (e) => {
    const csv = e.target.result;
    const array = csv.split(`\n`).map((line) => {
      return line.split(",");
    });

    let index = array[0].findIndex(
      (x) => x.toLowerCase() === "emails" || x.toLowerCase() === "email"
    );

    let emails = [];
    for (let i = 1; i < array.length; i++) {
      const email = array[i][index];
      if (email) {
        emails.push(email);
      }
    }
    setEmailsList(emails);
  };

  const handleChangeFile = (file) => {
    setUploadCompleted(false);
    setInvalidEmails([]);

    if (file) {
      let fileData = new FileReader();
      fileData.onloadend = extractFile;
      fileData.readAsText(file);
    }
  };

  const uploadEmail = async (data, index) => {
    try {
      await pb.collection("users").create(data);
    } catch (e) {
      const element = {
        index,
        email: data.email,
      };
      setInvalidEmails((invalidEmails) => [...invalidEmails, element]);
    }
  };

  const submitEmails = (e) => {
    e.preventDefault();

    for (let i = 0; i < emailsList.length; i++) {
      const data = {
        email: emailsList[i],
        active: true,
      };
      uploadEmail(data, i);
    }
    setUploadCompleted(true);
  };
  return (
    <main>
      <h2>Upload the .csv file with the email list to import</h2>
      <p>
        <i>It should have at least one column named "email" or "emails"</i>
      </p>

      <form
        className="import_form"
        onChange={(e) => handleChangeFile(e.target.files[0])}
      >
        <input
          className="upload_input"
          type="file"
          name="imported_file"
          required
        />
      </form>
      {!uploadCompleted && emailsList && (
        <section className="rendered_emails_list">
          <ul>
            {emailsList.map((email, index) => (
              <li key={index}>{email}</li>
            ))}
          </ul>

          <button type="submit" className="submit_btn" onClick={submitEmails}>
            Upload {emailsList.length} emails
          </button>
        </section>
      )}
      {uploadCompleted && (
        <>
          <p>
            Upload completed with {invalidEmails.length} error
            {invalidEmails.length > 1 ? "s" : ""}
          </p>
          <ul>
            {invalidEmails.map((line, i) => (
              <li key={i}>
                Line {line.index} : {line.email}
              </li>
            ))}
          </ul>
        </>
      )}
    </main>
  );
}
